<template>
  <div>
    <div class="header">
      <span class="header_text">管理后台</span>
      <el-dropdown @command="itemClick">
        <i class="mua-icon-person"></i>
         <el-dropdown-menu slot="dropdown">
           <el-dropdown-item disabled>{{userName}}</el-dropdown-item>
           <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
         </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userName: { default: '' }
  },
  methods: {
    itemClick (command) {
      if (command === 'logout') {
        this.$emit('logout')
      }
    }
  }
}
</script>

<style>
  .header{
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header_text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 26px;
  }
  .mua-icon-person {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: block;
    position: relative;
    background-size: contain;
    /*top: -3px;*/
    background-image: url('../assets/user@3x.png');
  }

  .mua-icon-person:hover{
    background-image: url('../assets/user_press@3x.png');
  }
</style>
