<template>
  <div class="mua-index-main"
       style="height: 100%;">
    <el-container style="height: 100%;">
      <el-aside width="200px"
                style="background-color: #002140;">
        <el-menu router
                 unique-opened
                 :default-active="activeLink"
                 background-color="#001529"
                 text-color="#A6AAAE"
                 class="mua-menu el-menu-vertical-demo index-page-menu"
                 style="border-right: none; width: 200px;"
                 active-text-color="#FFFFFF"
                 @select="selectMenuChange"
                 @open="openSubMenu"
                 @close="closeSubMenu">
          <template v-for="(item, a) in menus">
            <el-menu-item :index="item.url"
                          :key="a"
                          v-if="item.children.length === 0">
              <div style="display: flex; align-items: center; justify-content: flex-start;">
                <i :class="item.icon + ' icon'" />
                <span slot="title"
                      style="font-size: 14px;font-weight: 500;">
                  {{item.menu_name}}
                </span>
              </div>
            </el-menu-item>
            <el-submenu :index="'' + item.id"
                        :key="a"
                        :class="Array.isArray(item.subs) && item.subs.includes(activeLink) ? 'is-active' : ''"
                        v-else>
              <template slot="title">
                <div style="display: flex; align-items: center; justify-content: flex-start;">
                  <i :class="item.icon + 'icon'" />
                  <span style="font-size: 14px;font-weight: 500;">
                    {{item.menu_name}}
                  </span>
                </div>
              </template>
              <template v-for="(son, b) in item.children">
                <el-menu-item :index="son.url"
                              :key="b"
                              v-if="son.children.length === 0">
                  {{son.name}}
                </el-menu-item>
                <el-submenu :index="'' +  son.id"
                            :key="b"
                            :class="Array.isArray(son.subs) && son.subs.includes(activeLink) ? 'is-active' : ''"
                            v-else>
                  <template slot="title"
                            style="font-size: 14px;font-weight: 500;">{{son.name}}</template>
                  <el-menu-item v-for="(grandson, c) in son.children"
                                :key="c"
                                :index="grandson.url">
                    {{grandson.menu_name}}
                  </el-menu-item>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <Header @logout="logout"
                  v-bind:userName="user ? user.user_name : ''"></Header>
        </el-header>
        <el-main class="view-main">
          <router-view style="flex: 1">路由</router-view>
          <Footer></Footer>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import store from 'store'
import storeKeys from '@/store/storeKeys.js'

export default {
  data () {
    return {
      user: {},
      openIndex: 0,
      activeLink: '/'
    }
  },
  computed: {
    menus () {
      return this.$store.state.menus
    }
  },
  methods: {
    logout () {
      this.$store.commit('saveUser', null)
      this.$store.commit('saveMenus', null)
      this.$router.push('Login')
    },
    selectMenuChange (url) {
      if (url.indexOf('http') !== -1 || url.indexOf('https') !== -1) {
        window.open(url)
        return
      }
      this.activeLink = url
      let isParent = false
      this.menus.forEach(item => {
        item.children.forEach(obj => {
          if (obj.link === url) {
            isParent = true
          }
        })
      })
      if (!isParent && this.openIndex !== -1) {
        if (this.$refs.elMenu && this.$refs.elMenu.close) {
          this.$refs.elMenu.close(this.openIndex)
        }
      }
    },
    openSubMenu (index) {
      this.openIndex = index
      this.activeLink = ''
      // global.$('.el-menu-vertical-demo.el-menu li').eq(index).siblings().removeClass('is-active')
    },
    closeSubMenu () {
      this.openIndex = -1
    }
  },
  mounted () {
    if (this.$route.path && this.$route.path === '/') {
      this.activeLink = this.$route.path
    } else {
      this.activeLink = '/Banner'
    }
    this.user = store.get(storeKeys.USER)
  },
  components: { Header, Footer },
}
</script>

<style lang="scss">
.mua-index-main .el-aside .el-menu .el-submenu .el-menu-item {
  padding: 0 0 0 40px !important;
  min-width: 150px;
}
.index-page-menu li:hover,
.el-submenu__title:hover {
  background: #13487a !important;
}
</style>

<style scoped>
.index-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  margin-right: 8px;
}
.dtb-community {
  background-image: url('../assets/icon_community.png');
  background-repeat: no-repeat;
}
.is-active .dtb-community,
.el-submenu:hover .dtb-community {
  background-image: url('../assets/icon_community_press.png');
}
.dtb-change {
  background-image: url('../assets/icon_change.png');
  background-repeat: no-repeat;
}
.is-active .dtb-change,
.el-submenu:hover .dtb-change {
  background-image: url('../assets/icon_change_press.png');
}
.dtb-commission {
  background-image: url('../assets/icon_commission.png');
  background-repeat: no-repeat;
}
.is-active .dtb-commission,
.el-submenu:hover .dtb-commission {
  background-image: url('../assets/icon_commission_press.png');
}
.dtb-notice {
  background-image: url('../assets/icon_notice.png');
  background-repeat: no-repeat;
}
.is-active .dtb-notice,
.el-submenu:hover .dtb-notice {
  background-image: url('../assets/icon_notice_press.png');
}
.dtb-survey {
  background-image: url('../assets/icon_survey_press.png');
  background-repeat: no-repeat;
}
.is-active .dtb-survey,
.el-submenu:hover .dtb-survey {
  background-image: url('../assets/icon_survey.png');
}
.dtb-vote {
  background-image: url('../assets/icon_vote.png');
  background-repeat: no-repeat;
}
.is-active .dtb-vote,
.el-submenu:hover .dtb-vote {
  background-image: url('../assets/icon_vote_press.png');
}
.link_name {
  margin-left: 63px;
}
.el-menu-item .icon {
  color: #a6aaae;
}
.el-menu-item.is-active .icon {
  color: white;
}
.el-menu-item:hover {
  color: #fff !important;
  background-color: #13487a !important;
}
.el-menu-item .is-active {
  color: #fff !important;
  opacity: 1;
  background-color: #000c17 !important;
}
.el-menu .is-opened span {
  color: #fff !important;
}
.el-submenu__title:hover {
  color: #fff !important;
  background: #13487a !important;
}
.el-submenu__title i {
  color: #fff !important;
}
.aside-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 24px;
  font-size: 13px;
  color: #fff;
  background-color: #002140;
}
.aside-header .title {
  margin-left: 18px;
}
.view-main {
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #f8f8f8;
}
</style>
